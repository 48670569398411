import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import "styles/global.scss";
import { colors } from "config/constant";
const Home = lazy(() => import("containers/Home"));
const ContactUs = lazy(() => import("containers/ContactUs"));
const InterviewAsService = lazy(() => import("containers/InterviewAsService"));
const Integration = lazy(() => import("containers/Integration"));
const ToolsHomePage = lazy(() => import("containers/ToolsHomePage/index"));
const AtsResumeCheckerResult = lazy(() =>
  import("components/Result/JobDescription/index")
);
const ResumeJdSyncerResult = lazy(() =>
  import("components/Result/ResumeJdSyncer/index")
);
const ResumeDataScout = lazy(() =>
  import("components/Result/ResumeDataScout/index")
);
const SmmHome = lazy(() => import("containers/SmmHome/index"));
const PrivacyPolicy = lazy(() => import("containers/PrivacyPolicy/index"));
const TermsCondition = lazy(() => import("containers/TermsConditions/index"));
const Product = lazy(() => import("containers/Product/index"));
const Permission = lazy(() => import("containers/GrantPermissions/index"));
const Apply = lazy(() => import("containers/Apply/index"));
const GetStarted = lazy(() => import("containers/GetStarted/index"));
const RecruitmentPlatform = lazy(() =>
  import("containers/RecruitmentPlatform/index")
);
const RecruitmentProcessOutsourcing = lazy(() =>
  import("containers/RPO/index")
);
const AboutUs = lazy(() => import("containers/AboutUs/index"));
const AgencyRecruitment = lazy(() => import("containers/AgencyRecruitment"));
const CampusRecruitment = lazy(() => import("containers/CampusRecruitment"));
const RecruitmentPlatformPricing = lazy(() =>
  import("containers/Pricing/RecruitmentPlatform")
);
const InterviewAsServicePricing = lazy(() =>
  import("containers/Pricing/IAAS.jsx")
);

const RecruitmentGlossary = lazy(() =>
  import("containers/Glossary/RecruitmentGlossary.jsx")
);
const IAASGlossary = lazy(() => import("containers/Glossary/IAASGlossary"));
const GlossaryContent = lazy(() =>
  import("containers/Glossary/GlossaryContent")
);
const AlterNative = lazy(() => import("containers/Alternative/index"));
const Explore = lazy(() => import("containers/Explore/index"));
const Solutions = lazy(() => import("containers/Solutions/index"));

const DefaultLayout = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Suspense
        fallback={
          <div style={{ height: "100vh", width: "100vw" }}>Loading..</div>
        }
      >
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
};

const NoHeaderFooterLayout = () => {
  return (
    <>
      <Suspense
        fallback={
          <div style={{ height: "100vh", width: "100vw" }}>Loading..</div>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
};

function App() {
  const path = window.location.pathname;

  if (path !== "/pricing/permission/apply") {
    sessionStorage.removeItem("searchParams");
    sessionStorage.removeItem("agree");
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: colors.green[0],
              colorPrimaryActive: colors.green[0],
              colorText: "#758b89",
              colorPrimaryTextActive: colors.white[0],
              colorBgBase: "#f6f8f8",
              fontFamily: "Manrope"
            }
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route element={<DefaultLayout />}>
                <Route exact path={"/"} element={<Home />} />
                <Route exact path={"/contact-us"} element={<ContactUs />} />
                {/* <Route exact path={"/blogs"} element={<BlogListing />} /> */}
                {/* <Route exact path={"/blogs/:id"} element={<Blog />} /> */}
                <Route
                  exact
                  path={"/products/recruitment-platform"}
                  element={<RecruitmentPlatform />}
                />
                <Route
                  exact
                  path={"/services/interview-as-a-service"}
                  element={<InterviewAsService />}
                />
                <Route
                  exact
                  path={"/services/recruitment-process-outsourcing"}
                  element={<RecruitmentProcessOutsourcing />}
                />
                <Route
                  exact
                  path={"/services/agency-recruitment"}
                  element={<AgencyRecruitment />}
                />
                <Route
                  exact
                  path={"/services/campus-recruitment"}
                  element={<CampusRecruitment />}
                />
                <Route exact path={"/integration"} element={<Integration />} />
                {/* <Route exact path={"/pricing"} element={<Pricing />} /> */}
                {/* <Route exact path={"/product"} element={<Suits />} /> */}
                <Route exact path={"/products"} element={<Product />} />
                <Route
                  exact
                  path={"/products/:id"}
                  element={<ToolsHomePage />}
                />
                <Route
                  exact
                  path={"/products/ats-resume-checker/result"}
                  element={<AtsResumeCheckerResult name="ats-resume-checker" />}
                />
                <Route
                  exact
                  path={"/products/linkedin-optimization/result"}
                  element={
                    <AtsResumeCheckerResult name="linkedin-optimization" />
                  }
                />
                <Route
                  exact
                  path={"/products/job-description-scanner/result"}
                  element={
                    <AtsResumeCheckerResult name="job-description-scanner" />
                  }
                />
                <Route
                  exact
                  path={"/products/resume-jd-syncer/result"}
                  element={<ResumeJdSyncerResult />}
                />
                <Route
                  exact
                  path={"/products/resume-data-scout/result"}
                  element={<ResumeDataScout />}
                />
                <Route
                  exact
                  path={"/products/job-posting-management/app"}
                  element={<SmmHome />}
                />
                <Route
                  exact
                  path={"/privacy-policy"}
                  element={<PrivacyPolicy />}
                />
                <Route
                  exact
                  path={"/terms-conditions"}
                  element={<TermsCondition />}
                />
                <Route
                  exact
                  path={"/pricing/recruitment-platform"}
                  element={<RecruitmentPlatformPricing />}
                />
                <Route
                  exact
                  path={"/pricing/interview-as-service"}
                  element={<InterviewAsServicePricing />}
                />
                <Route
                  exact
                  path={"/pricing/permission"}
                  element={<Permission />}
                />
                <Route
                  exact
                  path={"/pricing/permission/apply"}
                  element={<Apply />}
                />
                <Route exact path={"/solutions/:id"} element={<Solutions />} />
                <Route
                  exact
                  path={"/glossary/recruitment-platform"}
                  element={<RecruitmentGlossary />}
                />
                <Route
                  exact
                  path={"/glossary/interview-as-service"}
                  element={<IAASGlossary />}
                />
                <Route
                  exact
                  path={"/glossary/recruitment-platform/:id"}
                  element={<GlossaryContent />}
                />
                <Route
                  exact
                  path={"/glossary/interview-as-service/:id"}
                  element={<GlossaryContent />}
                />
                <Route
                  exact
                  path={"/comparison/:id"}
                  element={<AlterNative />}
                />
                <Route exact path={"/explore/:id"} element={<Explore />} />
                comparison
                <Route exact path={"/about-us"} element={<AboutUs />} />
              </Route>
              <Route element={<NoHeaderFooterLayout />}>
                <Route exact path={"/get-started"} element={<GetStarted />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
